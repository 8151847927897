import { JwtPayload } from 'jwt-decode';

export interface User {
  userId?: number;
  customerId?: number;
  displayName?: string;
  email?: string;
  image?: string;
  token?: string;
  tokenExpiration?: number;
  userRole?: UserRole;
  userType?: UserType;
}

export interface LoginSessionInfo {
  authToken: string;
  refreshToken: string;
  expiresIn: number;
}

export interface DecodedToken extends JwtPayload {
  name?: string;
  email?: string;
  instaclause: { [key: string]: string };
}

export enum UserRole {
  Admin = 'Admin',
  User = 'User'
}

export enum UserType {
  Expert = 'Expert',
  Accountant = 'Accountant',
  BackOffice = 'BackOffice'
}

// api
export interface UserResponse {
  user: User;
}

export interface AccountantsResponse {
  accountants: User[];
}

export interface LoginResponse {
  sessionInfo: LoginSessionInfo;
}
