import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken<Environment>('environment');

export interface Environment {
  production: boolean;
  appName: string;
  apiBaseUrl: string;
  apiAuthUrl: string;
  version: string;
}
