import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, filter } from 'rxjs';

import { Environment, ENVIRONMENT } from '../environments/environment.token';

@Injectable({ providedIn: 'root' })
export class PageService {

  public appName!: string;
  readonly currentPath$ = new BehaviorSubject<string>('');
  readonly breadcrumbs$ = new BehaviorSubject<string[]>([]);

  constructor(private router: Router, @Inject(ENVIRONMENT) private environment: Environment) {
    this.updateAppName();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentPath();
      });
  }

  private updateAppName(): void {
    this.appName = this.environment.appName;
    console.log(`{page} updateAppName(): ${this.appName}`);
  }

  private updateCurrentPath(): void {
    const url = this.router.url;
    const breadcrumbs = url.split('/').filter(path => path);
    this.currentPath$.next(url);
    this.breadcrumbs$.next(breadcrumbs);

    console.log(`{page} updateCurrentPath(): ${url}`);
  }
}
