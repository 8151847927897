import {
  HttpBackend,
  HttpClient,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { jwtDecode } from 'jwt-decode';
import {
  BehaviorSubject,
  Observable, catchError, firstValueFrom, map, of, tap,
} from 'rxjs';

import { ENVIRONMENT } from '../environments/environment.token';
import {
  DecodedToken, LoginResponse, LoginSessionInfo, User,
} from '../models/user.model';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  user$: Observable<User | null>;

  private userSubject = new BehaviorSubject<User | null>(null);
  private sessionInfoKey = 'sessionInfo';
  private rememberMeKey = 'rememberMe';
  private baseUrl = inject(ENVIRONMENT).apiAuthUrl;
  private httpBackend = inject(HttpBackend);
  private http = new HttpClient(this.httpBackend);
  private router = inject(Router);

  constructor(
  ) {
    this.user$ = this.userSubject.asObservable();
    this.loadSession();
  }

  getUser(): User | null {
    return this.userSubject.value;
  }

  login(email: string, password: string, rememberMe: boolean): Observable<User | null> {
    const url = `${this.baseUrl}/auth/login`;

    return this.http.post<LoginResponse>(url, { email, password }).pipe(
      map(response => {
        this.saveSessionInfo(response.sessionInfo, rememberMe);

        return this.decodeToken(response.sessionInfo.authToken);
      }),
      tap(user => this.userSubject.next(user)),
      catchError(() => of(null)),
    );
  }

  async logout() {
    this.clearSessionInfo();
    // await firstValueFrom(this.revokeToken());
    this.userSubject.next(null);
    this.router.navigate([ './login' ]);
  }

  refreshToken(): Observable<User | null> {
    const url = `${this.baseUrl}/auth/refresh`;
    const sessionInfo = this.getSessionInfo();

    if (!sessionInfo) {
      return of(null);
    }

    const parsedSessionInfo: LoginSessionInfo = JSON.parse(sessionInfo);
    const rememberMe = this.verifyRememberMe();
    const params = new HttpParams().set('refreshToken', parsedSessionInfo.refreshToken);

    return this.http.get<LoginResponse>(url, { params }).pipe(
      map(newSessionInfo => {
        this.saveSessionInfo(newSessionInfo.sessionInfo, rememberMe);

        return this.decodeToken(newSessionInfo.sessionInfo.authToken);
      }),
      tap(user => this.userSubject.next(user)),
      catchError((error) => {
        console.error('{ Auth Service - refreshToken() }: ERROR', error);
        this.logout();

        return of(null);
      }),
    );
  }

  revokeToken(): Observable<boolean> {
    const url = `${this.baseUrl}/auth/revoke`;
    const user = this.userSubject.value;

    if (user && user.email) {
      return this.http.post(url, { email: user.email }, { observe: 'response' })
        .pipe(
          map((response: HttpResponse<any>) => {
            return response.status === 200;
          }),
          catchError(() => {
            return of(false);
          })
        );
    }

    return of(false);
  }

  // private fetchUserWithToken(token: string): Observable<User | null> {
  //   const url = `${this.baseUrl}/users/current`;
  //   // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   console.log('fetchUserWithToken >>>');

  //   return this.http.get<UserResponse>(url).pipe(
  //     map(user => ({ ...user.user, token }))
  //     // catchError(() => of(null))
  //   );
  // }

  private decodeToken(token: string): User | null {
    if (token) {
      const data = jwtDecode<DecodedToken>(token);

      return {
        ...data.instaclause,
        email: data.email,
        displayName: data.name,
        token,
        tokenExpiration: data.exp,
      } as User;
    }

    return null;
  }

  private async loadSession() {
    const sessionInfo = this.getSessionInfo();

    if (sessionInfo) {
      const parsedSessionInfo: LoginSessionInfo = JSON.parse(sessionInfo);
      const user = this.decodeToken(parsedSessionInfo.authToken);

      if (user && user.tokenExpiration && user.tokenExpiration > Date.now() / 1000) {
        this.userSubject.next(user);
      } else {
        await firstValueFrom(this.refreshToken());
      }
    }
  }

  private getSessionInfo(): string | null {
    return localStorage.getItem(this.sessionInfoKey) || sessionStorage.getItem(this.sessionInfoKey);
  }

  private verifyRememberMe(): boolean {
    const res = localStorage.getItem(this.rememberMeKey) ||
     sessionStorage.getItem(this.rememberMeKey);

    return res ? JSON.parse(res) : false;
  }

  private saveSessionInfo(sessionInfo: LoginSessionInfo, rememberMe: boolean): void {
    const storage = rememberMe ? localStorage : sessionStorage;

    storage.setItem(this.sessionInfoKey, JSON.stringify(sessionInfo));
    storage.setItem(this.rememberMeKey, JSON.stringify(rememberMe));
  }

  private clearSessionInfo() {
    localStorage.removeItem(this.sessionInfoKey);
    sessionStorage.removeItem(this.sessionInfoKey);
  }
}
