import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { switchMap, take } from 'rxjs';

import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  return authService.user$.pipe(
    take(1),
    switchMap(user => {
      if (user && user.token) {
        if (user.tokenExpiration && user.tokenExpiration < Date.now() / 1000) {
          return authService.refreshToken().pipe(
            switchMap(user => {
              if (user && user.token) {
                const cloned = setAuthHeader(req, user.token);

                return next(cloned);
              }

              return next(req);
            }),
          );
        } else {
          const cloned = setAuthHeader(req, user.token);

          return next(cloned);
        }
      }

      return next(req);
    }),

  );
};

const setAuthHeader = (req: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
  return req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });
};
