import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import {
  Observable, catchError, map, of, take,
} from 'rxjs';

import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (): Observable<boolean> | boolean => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.user$.pipe(
    take(1),
    map(user => {
      if (user) {
        return true;
      } else {
        router.navigate([ '/login' ]);

        return false;
      }
    }),
    catchError(() => {
      router.navigate([ '/login' ]);

      return of(false);
    })
  );
};

